export default (app, inject) => {
  const prefixLinks = (link) => {
    if (process.client) {
      let ftp = process.env.FTP
      const prefixes = [
        'furniture/',
        'packcom/',
        'pdf/',
        'products/',
        'program/',
        'promoters/',
        'screenshot/',
        'streaming/',
        'users/',
        'picturesandmovies/',
      ]

      if (!app.store.getters.isOnline) {
        link = `${process.env.LOCAL_IMAGES || ''}/${link.replace(`${ftp}`, '')}`
        link = link.replace('//', '/')
        ftp = process.env.LOCAL_IMAGES || ''
      }

      return link && prefixes.includes(link.split('/')[0] + '/')
        ? prefixBuilder(ftp, link)
        : link
    }
  }
  inject('prefixLinks', prefixLinks)
}

function prefixBuilder (ftp, link) {
  return ftp[ftp.length - 1] === '/'
    ? `${ftp}${link}`
    : `${ftp}/${link}`
}
